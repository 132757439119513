import './Durations.css';
import DurationsList from '../components/DurationsList'

const Durations = ( { kids, dateReadable, durations, durationsDatePrevDay, durationsDateNextDay, currentTime } ) => {
    return (
        <>
          <div className="duration-prev-next-btns">
            <button onClick={durationsDatePrevDay}>&lt;</button>
            <h2>{dateReadable}</h2>
            <button onClick={durationsDateNextDay}>&gt;</button>
          </div>
          <div className="DurationsListsContainer">
            {kids.map((kid) => (
                <DurationsList
                    key={kid.id}
                    kid={kid}
                    durations={durations[kid.id]}
                    currentTime={currentTime}
                />
            ))}
          </div>
        </>
    )
};

export default Durations;
