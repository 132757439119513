import { useState, useEffect } from 'react'
import './Events.css';

import { fetchEvents } from '../api'
import EventsList from '../components/EventsList'

const Events = (props) => {
    const [events, setEvents] = useState([])

    // Run on start
    useEffect(() => {
        getEvents()
    }, [])

    const getEvents = async () => {
        const eventsFromServer = await fetchEvents()
        setEvents(eventsFromServer)
    }

    return (
        <EventsList events={events} />
    )
};

export default Events;
