import React from 'react';
import { Link } from "react-router-dom";

import './Nav.css'

const Nav = () => {
    return (
        <nav className="nav-main">
          <Link to="/">Hem</Link>
          <Link to="/events">Händelser</Link>
        </nav>
    )
};


export default Nav;
