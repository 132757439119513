import './EventsItem.css';

import EventsItem from '../components/EventsItem'

const EventsList = ({ events }) => {
    
    return (
        <section className="events-list">
          {events.map((event, index) => (
              <EventsItem key={event.id} event={event} />
          ))}
        </section>
    )

}

export default EventsList;
