import React from 'react';

import Nav from '../components/Nav'

const Header = (props) => {

    return (
        <header>
          <h1>Samla speltid</h1>
          <Nav />
        </header>
    )
};

export default Header;
