import React from 'react';
import './DurationList.css';

import DurationItem from './DurationItem'

const DurationsList = ({ kid, durations, currentTime }) => {
    if (!durations) {
        return (
            <section className="duration-list">
              <p>Laddar...</p>
            </section>
        )
    }

    return (
        <section className="duration-list">
          <h2>{ kid.name }</h2>
          <ul>
            {durations.map((duration, index) => (
                <DurationItem
                    key={index}
                    duration={duration}
                    currentTime={currentTime}
                />
            ))}
          </ul>
        </section>
    )
};

export default DurationsList;
