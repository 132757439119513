const formatDuration = (seconds) =>  {
    seconds = Math.trunc(seconds);  // Makes sure seconds are an integer.
    const numyears = Math.floor(seconds / 31536000);
    const numdays = Math.floor((seconds % 31536000) / 86400); 
    const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    var durationHumanReadable = []
    durationHumanReadable.push(numyears ? numyears + ' år ' : '')
    durationHumanReadable.push(numdays ? numdays + ' dagar ' : '')
    durationHumanReadable.push(numhours ? numhours + ' timmar ' : '')
    durationHumanReadable.push(numminutes ? numminutes + ' min ' : '')
    durationHumanReadable.push(numseconds ? numseconds + ' s' : '')
    
    return durationHumanReadable.join('')
}

const formatTime = (date) => {
    const hours = date.getHours()
    // Ugly fix to pad minutes with a zero if below 10.
    const minutes = (date.getMinutes()<10 ? '0':'') + date.getMinutes()
    return `${hours}:${minutes}`
}

const formatDateTime = (date) => {
    const d = new Date(date)
    return d.toLocaleString('sv-SE')
}

const dateTimeToIsoDateOnly = (date) => {
    var iso = date.toISOString()
    return(iso.substr(0, iso.indexOf('T')))
}

export {dateTimeToIsoDateOnly, formatDuration, formatDateTime, formatTime};


