import React from 'react';
import {formatDateTime} from '../helpers/formatters';


const EventsItem = ( {event} ) => {
    const getBgColor = () => {
        if (event.state === 'OUT') {
            return ('went-out')
        } else if (event.state ==='IN') {
            return ('went-in')
        } else
            return ('')
    }

    const stateMap = {
        IN: 'gick in',
        OUT: 'gick ut'
    }
    
    return (
        <div className={`events-item ${getBgColor()}`}>
          <h3>{formatDateTime(event.event_date)}</h3>
          <p>{event.kid.name} {stateMap[event.state]}</p>
        </div>
    )
};

export default EventsItem;
