import { dateTimeToIsoDateOnly } from './helpers/formatters'

const baseUrl = process.env.REACT_APP_API_BASE_URL

let headers = new Headers();

if (process.env.REACT_APP_API_USE_TOKEN === 'true') {
    headers.append('Authorization', `Token ${process.env.REACT_APP_API_TOKEN}`);
}

const fetchKids = async () => {   
    const res = await fetch(`${baseUrl}kids/`, {
        headers: headers,
    })
    const data = await res.json()
    return data
}

const fetchDurations = async (id, durationsDate) => {
    const res = await fetch(
        `${baseUrl}durations/?format=json&kid_id=${id}&date=${dateTimeToIsoDateOnly(durationsDate)}`, {
            headers: headers,   
        }
    )
    const data = await res.json()
    return data
}

const fetchEvents = async () => {
    const res = await fetch(
        `${baseUrl}events/`, {
            headers: headers
        }
    )
    const data = await res.json()
    return data
}


export { fetchDurations, fetchEvents, fetchKids }
