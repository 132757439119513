import React from 'react';
import './DurationItem.css';
import {formatDuration, formatTime} from '../helpers/formatters';

const DurationItem = ({ duration, currentTime }) => {
    const eventOut = new Date(duration.event_out);
    const eventIn = duration.event_in ? new Date(duration.event_in) : null;
    const fullCycle = duration.full_cycle;
    const backgroundClass = fullCycle ? 'full-cycle' : 'on-going';
    let formattedDuration;

    if (fullCycle) {
        formattedDuration = formatDuration(duration.duration)
    } else {
        /* Calculate formattedDuration on the fly to make sure timer
         * is running even when api are not updated.
         */
        formattedDuration = formatDuration((currentTime - eventOut)/1000)
    }

    return(
        <li className={`duration-item ${backgroundClass}`}>
          <h3>
            {formatTime(eventOut)}
            {eventIn ? ` - ${formatTime(eventIn)}` : ''}
            {fullCycle ? '' : ' (pågående)'}
          </h3>
          <p>{formattedDuration}</p>
        </li>
    )
};


export default DurationItem;
