import { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Header from './components/Header'
import Durations from './routes/Durations'
import Events from './routes/Events'

import {  fetchDurations, fetchKids } from './api'
import './App.css';

function App() {
    const [currentTime, setCurrentTime] = useState(new Date())
    const [durations, setDurations] = useState({})
    const [durationsDate, setDurationsDate] = useState(new Date())
    const [kids, setKids] = useState([])

    // Run on start
    useEffect(() => {
        setInterval(() => {
            setCurrentTime(new Date())
        }, 1000)
        getKids()
    }, [])

    useEffect(() => {
        // If kids ever got updated more than ones, this would cause trouble
        setInterval(() => {
            kids.map(kid => kid.id).forEach(id => getDurations(id))
        }, 2000)

        kids.map(kid => kid.id).forEach(id => getDurations(id))
        // eslint-disable-next-line
    }, [kids])

    useEffect(() => {
        kids.map(kid => kid.id).forEach(id => getDurations(id))
        // eslint-disable-next-line
    }, [durationsDate])

    const durationsDatePrevDay = () => {
        var d = durationsDate
        d.setDate(d.getDate() - 1)
        setDurationsDate(d)
    }

    const durationsDateNextDay = () => {
        var d = durationsDate
        d.setDate(d.getDate() + 1)
        setDurationsDate(d)
    }
    
    const getDurations = async (kidId) => {
        const durationsFromServer = await fetchDurations(kidId, durationsDate)
        setDurations(prevState => ({
            ...prevState,
            [kidId]: durationsFromServer
        }))
    }

    const getKids = async () => {
        const kidsFromServer = await fetchKids()
        setKids(kidsFromServer)
    }

    return (
        <Router>
          <Header />
          <Switch>
            <Route path="/events">
              <Events />
            </Route>
            <Route path="/">
              <Durations kids={kids}
                         dateReadable={new Intl.DateTimeFormat('sv-SE', {dateStyle: 'full'}).format(durationsDate)}
                         durations={durations}
                         durationsDatePrevDay={durationsDatePrevDay}
                         durationsDateNextDay={durationsDateNextDay}
                         currentTime={currentTime}
              />
            </Route>
          </Switch>

        </Router>
    )
}

export default App;
